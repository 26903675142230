import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import styles from "../styles/style.js";
import Headings from "./Headings.js";

const TalentedPeople = () => {
  const data = useStaticQuery(graphql`
    query {
      teamProfileImages: allFile(
        filter: {
          name: { in: ["02-alfie-barnard", "02-Iulian-jorj", "03-ben-winton", "07-tugrul-bakirci"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
    }
  `);
  //console.log("data: ", data);

  const alfieBarnard = data.teamProfileImages.nodes.find((image) => {
    return image.name === "02-alfie-barnard";
  });

  const iulianJorj = data.teamProfileImages.nodes.find((image) => {
    return image.name === "02-Iulian-jorj";
  });

  const benWinton = data.teamProfileImages.nodes.find((image) => {
    return image.name === "03-ben-winton";
  });

  const tugrulBakirci = data.teamProfileImages.nodes.find((image) => {
    return image.name === "07-tugrul-bakirci";
  });

  return (
    <section
      id="talented-people"
      className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={` ${styles.boxWidth}`}>
        <Headings
          titleTop="Our talent"
          titleBottom="on site"
          description="Intact Electrical strives to create a great working environment, fulfilling incentives that drive our workforce to commit to us and our clients' every need. Please meet our team below."
        />
        <section className="bg-white">
          <div className="grid lg:grid-cols-3 ss:grid-cols-2 grid-cols-1 gap-6 row-gap-5 mt-6 mb-6">
            
            <div className="flex flex-col text-center items-center transition-colors duration-300 transform rounded-xl hover:border-transparent group ">
              <div className="flex flex-col items-center">
                <GatsbyImage
                  image={iulianJorj.childImageSharp.gatsbyImageData}
                  alt={iulianJorj.name}
                  className="object-cover rounded-xl aspect-square ring-2 ring-[#a7a4a4]"
                  loading="lazy"
                />
              </div>

              <h1 className="text-2xl font-semibold text-gray-700 capitalize mt-4">
                <span className="text-gray-700 group-hover:text-tertiary">
                Iulian Jorj
                </span>
              </h1>

              <p className="text-base text-gray-500 capitalize dark:text-gray-300">
                <span className="text-dimGrey group-hover:text-gray-700">
                  Lead Electrical Engineer
                </span>                
              </p>
            </div>

            <div className="flex flex-col items-center transition-colors duration-300 transform  rounded-xl hover:border-transparent group ">
              <div className="flex flex-col items-center">
                <GatsbyImage
                  image={alfieBarnard.childImageSharp.gatsbyImageData}
                  alt={alfieBarnard.name}
                  className="object-cover rounded-xl aspect-square ring-2 ring-[#a7a4a4] "
                  loading="lazy"
                />
              </div>

              <h1 className="text-2xl font-semibold text-gray-700 capitalize mt-4">
                <span className="text-gray-700 group-hover:text-tertiary">
                Alfie Barnard
                </span>
              </h1>

              <p className="text-base text-gray-500 capitalize dark:text-gray-300">
                <span className="text-dimGrey group-hover:text-gray-700">
                Electrician
                </span>                
              </p>
            </div>

            {/* <div className="flex flex-col items-center transition-colors duration-300 transform rounded-xl hover:border-transparent group ">
              <div className="flex flex-col items-center">
                <GatsbyImage
                  image={benWinton.childImageSharp.gatsbyImageData}
                  alt={benWinton.name}
                  className="object-cover rounded-xl aspect-square ring-2 ring-[#a7a4a4]"
                  loading="lazy"
                />
              </div>

              <h1 className="text-2xl font-semibold text-gray-700 capitalize mt-4">
                <span className="text-gray-700 group-hover:text-tertiary">
                Ben Winton
                </span>
              </h1>

              <p className="text-base text-gray-500 capitalize dark:text-gray-300">
                <span className="text-dimGrey group-hover:text-gray-700">
                Trainee Electrician
                </span>                
              </p>
            </div> */}

            <div className="flex flex-col items-center transition-colors duration-300 transform rounded-xl hover:border-transparent group ">
              <div className="flex flex-col items-center">
                <GatsbyImage
                  image={tugrulBakirci.childImageSharp.gatsbyImageData}
                  alt={tugrulBakirci.name}
                  className="object-cover    rounded-xl aspect-square ring-2 ring-[#a7a4a4]"
                  loading="lazy"
                />
              </div>

              <h1 className="text-2xl font-semibold text-gray-700 capitalize mt-4">
                <span className="text-gray-700 group-hover:text-tertiary">
                Tugrul Bakirci
                </span>
              </h1>

              <p className="text-base text-gray-500 capitalize dark:text-gray-300">
                <span className="text-dimGrey group-hover:text-gray-700">
                Trainee Electrician
                </span>                
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default TalentedPeople;
