import React from "react";
import styles from "../styles/style";

const subTitle = ({ titleTop, titleBottom }) => {
  //console.log("Details: ", titleTop, titleBottom);
  return (
    <h2 className={`${styles.headingLeadershipProfile} mb-4`}>
      {titleTop ? (
        <span className="text-gray-700 group-hover:text-tertiary">
          {titleTop}
        </span>
      ) : (
        <span className="text-gray-700 group-hover:text-tertiary">
          Default Top subTitle
        </span>
      )}
      {/* <br className="xx:block hidden" />{" "} */}
      <br />
      {titleBottom ? (
        <span className="text-dimGrey group-hover:text-white">
          {titleBottom}
        </span>
      ) : null}
    </h2>
  );
};

export default subTitle;
