import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import styles from "../styles/style.js";
import Headings from "./Headings.js";
import SubTitle from "./subTitle.js";

const LeadershipTeam = () => {
  /* const data = useStaticQuery(graphql`
    query {
      teamProfileImages: allFile(
        filter: {
          name: { in: ["01-alfie-barnard", "02-Iulian-jorj", "03-ben-winton"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.2
            )
          }
        }
      }
    }
  `); */
  //console.log("data: ", data);
  
  return (
    <section
      id="leadershipTeam"
      className={`bg-[#ffffff] mb-8 ${styles.paddingX} ${styles.flexCenter}`}
    >
      <div className={`mt-6 ${styles.boxWidth}`}>
        <Headings
          titleTop="Leadership"
          titleBottom="team profiles"
          description="Soon after founding Intact Electrical, we established ourselves simply with client recommendations. Since then, we have grown rapidly, taking on both commercial contracts and domestic work."
        />
        <div className="mt-6">
          <div className="grid grid-cols-1 gap-8 md:gap-2 mt-8 xl:mt-4 md:grid-cols-2 xl:grid-cols-2">
            <div className="px-6 py-6 transition-colors duration-300 transform border rounded-xl hover:border-transparent group hover:bg-black sm:mx-2 ring-1 ring-[#a7a4a4]">
              <div className="flex flex-col xs:-mx-4 xs:mb-6 xs:flex-row">
                <StaticImage
                  src="../images/profile-images/richard-profile-picture-v4.jpg"
                  placeholder="blurred"
                  formats={["auto"]}
                  alt="meet the team"
                  aspectRatio={1.0}
                  className="flex-shrink-0 object-cover w-32 h-32 rounded-lg sm:mx-4 ring-2 ring-[#a7a4a4]"
                />
                <div className="sm:mx-2 sm:mt-0 xs:ml-6 xs:mt-0 xx:mt-6 xx:ml-0 mt-6">
                  <SubTitle titleTop="Richard Barber" titleBottom="Director" />
                </div>
              </div> 

              <p 
                //className="sm:mt-2 text-base text-gray-700 group-hover:text-gray-200"
                className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-gray-200`}>
                Hi, I'm Richard, a director here at Intact Electrical where we
                strive to deliver outstanding service to our clients. With my
                vast experience in all fields of electrical installations I take
                great pride in the work we carry out for our clients.
              </p>

              <p 
                //className="mt-2 text-base text-gray-700 group-hover:text-gray-200"
                className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-gray-200 mt-3 mb-3`}>
                Away from my role at Intact Electrical, apart from being a
                devoted family man, I'm also an FA qualified football coach for
                local youth team Burnham Junior FC, who are also one of our
                partners, and a regular supporter at Burnham Football Club.
              </p>

              <Link 
                to="/articles/football-coach"
                alt="richard barber the football coach"
                title="richard barber the football coach"
                aria-label="richard barber the football coach"
                className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-tertiary`}
              >Read more...</Link>

              <div className="flex mt-4 -mx-2">
                <a
                  href="https://www.instagram.com/intact_electrical/"
                  alt="link to Instagram"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="Instagram icon"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.469A3.334,3.334,0,1,1,15.334,12,3.334,3.334,0,0,1,12,15.334Zm6.539-8.672a1.2,1.2,0,1,1-1.2-1.2h0A1.2,1.2,0,0,1,18.539,6.662ZM21.476,5.45a4.917,4.917,0,0,0-1.154-1.772,4.894,4.894,0,0,0-1.77-1.153,7.318,7.318,0,0,0-2.428-.464C15.058,2.012,14.717,2,12,2s-3.057.011-4.124.061a7.326,7.326,0,0,0-2.427.464A4.9,4.9,0,0,0,3.679,3.678,4.882,4.882,0,0,0,2.525,5.45a7.332,7.332,0,0,0-.464,2.427C2.011,8.943,2,9.284,2,12s.011,3.057.061,4.123a7.332,7.332,0,0,0,.464,2.427,4.882,4.882,0,0,0,1.154,1.772A4.915,4.915,0,0,0,5.45,21.475a7.337,7.337,0,0,0,2.427.464C8.944,21.988,9.285,22,12,22s3.057-.011,4.123-.061a7.333,7.333,0,0,0,2.428-.464,5.113,5.113,0,0,0,2.925-2.925,7.306,7.306,0,0,0,.464-2.427c.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123A7.326,7.326,0,0,0,21.476,5.45ZM20.141,16.041A5.52,5.52,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.546,5.546,0,0,1-1.857.344c-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058A5.546,5.546,0,0,1,6.1,19.8a3.1,3.1,0,0,1-1.15-.748,3.092,3.092,0,0,1-.748-1.15,5.494,5.494,0,0,1-.344-1.857C3.812,14.987,3.8,14.671,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.205,6.1a3.1,3.1,0,0,1,.748-1.15A3.072,3.072,0,0,1,6.1,4.2,5.494,5.494,0,0,1,7.96,3.86C9.014,3.811,9.331,3.8,12,3.8s2.987.011,4.042.059A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.494,5.494,0,0,1,.344,1.857C20.19,9.014,20.2,9.33,20.2,12S20.19,14.986,20.141,16.041Z"/></svg>
                </a> 

                <a
                  href="https://www.facebook.com/intactelectrics"
                  alt="link to facebook"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="Facebook icon"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
                  </svg>
                </a>

                <a
                  href="https://uk.linkedin.com/company/intact-electrical-limited"
                  alt="link to LinkedIn"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="LinkedIn icon"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
                  <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
                  <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" />
                  </svg>
                </a> 
              </div>
            </div>

            <div className="px-6 py-6 transition-colors duration-300 transform border rounded-xl hover:border-transparent group hover:bg-black sm:mx-2 ring-1 ring-[#a7a4a4]">
            <div className="flex flex-col xs:-mx-4 xs:mb-6 xs:flex-row">
                <StaticImage
                  src="../images/profile-images/gavin-profile-picture-v3.jpg"
                  placeholder="blurred"
                  formats={["auto"]}
                  alt="meet the team"
                  aspectRatio={1.0}
                  className="flex-shrink-0 object-cover w-32 h-32 rounded-lg sm:mx-4 ring-2 ring-[#a7a4a4]"
                />
                <div className="sm:mx-2 sm:mt-0 xs:ml-6 xs:mt-0 xx:mt-6 xx:ml-0 mt-6">
                  <SubTitle titleTop="Gavin Little" titleBottom="Director" />
                </div>
              </div>

              <p className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-gray-200`}>
                Hi, I'm Gavin, one of two directors of Intact Electrical. After
                20+ years' experience as a qualified electrician I was eager to
                work towards running my own electrical business. I'm proud of
                both the team and reputation we have built.
              </p>

              <p className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-gray-200 mt-3 mb-3`}>
                I am a father of a wonderful daughter and happily married to a
                very patient woman… who puts up with my love for extreme sports.
                From motorbike racing to snowboarding, if it produces a surge of
                adrenaline then sign me up!
              </p>

              <Link 
                to="/articles/racer-at-heart"
                alt="Gavin little racer at heart"
                title="Gavin little racer at heart"
                aria-label="Gavin little racer at heart"
                className={`${styles.paragraph2} text-left  max-w-[100%] group-hover:text-tertiary`}
              >Read more...</Link>

              <div className="flex mt-4 -mx-2">
                <a
                  href="https://www.instagram.com/intact_electrical/"
                  alt="link to Instagram"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="Instagram icon"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.469A3.334,3.334,0,1,1,15.334,12,3.334,3.334,0,0,1,12,15.334Zm6.539-8.672a1.2,1.2,0,1,1-1.2-1.2h0A1.2,1.2,0,0,1,18.539,6.662ZM21.476,5.45a4.917,4.917,0,0,0-1.154-1.772,4.894,4.894,0,0,0-1.77-1.153,7.318,7.318,0,0,0-2.428-.464C15.058,2.012,14.717,2,12,2s-3.057.011-4.124.061a7.326,7.326,0,0,0-2.427.464A4.9,4.9,0,0,0,3.679,3.678,4.882,4.882,0,0,0,2.525,5.45a7.332,7.332,0,0,0-.464,2.427C2.011,8.943,2,9.284,2,12s.011,3.057.061,4.123a7.332,7.332,0,0,0,.464,2.427,4.882,4.882,0,0,0,1.154,1.772A4.915,4.915,0,0,0,5.45,21.475a7.337,7.337,0,0,0,2.427.464C8.944,21.988,9.285,22,12,22s3.057-.011,4.123-.061a7.333,7.333,0,0,0,2.428-.464,5.113,5.113,0,0,0,2.925-2.925,7.306,7.306,0,0,0,.464-2.427c.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123A7.326,7.326,0,0,0,21.476,5.45ZM20.141,16.041A5.52,5.52,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.546,5.546,0,0,1-1.857.344c-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058A5.546,5.546,0,0,1,6.1,19.8a3.1,3.1,0,0,1-1.15-.748,3.092,3.092,0,0,1-.748-1.15,5.494,5.494,0,0,1-.344-1.857C3.812,14.987,3.8,14.671,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.205,6.1a3.1,3.1,0,0,1,.748-1.15A3.072,3.072,0,0,1,6.1,4.2,5.494,5.494,0,0,1,7.96,3.86C9.014,3.811,9.331,3.8,12,3.8s2.987.011,4.042.059A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.494,5.494,0,0,1,.344,1.857C20.19,9.014,20.2,9.33,20.2,12S20.19,14.986,20.141,16.041Z"/></svg>
                </a> 

                <a
                  href="https://www.facebook.com/intactelectrics"
                  alt="link to facebook"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="Facebook icon"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
                  </svg>
                </a>

                <a
                  href="https://uk.linkedin.com/company/intact-electrical-limited"
                  alt="link to LinkedIn"
                  className="mx-2 text-gray-600 group-hover:text-quinary"
                  aria-label="LinkedIn icon"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="w-7 h-7 fill-current"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
                  <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
                  <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" />
                  </svg>
                </a> 
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadershipTeam;
